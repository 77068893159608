<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('商品名管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('商品名')" v-model="query.name" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('规格名')" v-model="query.simpleName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('短商品名')" v-model="query.shortName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-s-goods"></i>
        </el-input>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar15" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()" v-if="hasPermission('simpleProductName:create')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="edit()" v-if="hasPermission('simpleProductName:update')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('simpleProductName:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getSimpleProductNames(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="success" @click="queryVisiable = true;" size="mini" v-if="hasPermission('simpleProductName:downloadOK')">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('OK货单查询下载')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('物流信息下载'),sheetName: 'Sheet1' })" v-if="hasPermission('express:download')">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="simpleProductNames" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')"  min-width="90" :fixed="isMinScreen ? null : 'left'" 
          sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <vxe-table-column field="name" :title="msg('商品名')" min-width="150" 
          sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column> 
      <vxe-table-column field="shortName" :title="msg('短商品名')" min-width="150" 
          sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>  
      <vxe-table-column field="simpleName" :title="msg('规格名')" min-width="150" 
          sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>  
      <vxe-table-column field="fullname" :title="msg('创建人')" min-width="150" 
          sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>   
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen">
    <simple-product-name-edit :id="currId" :simpleProductName="curr" v-on:closeDg="closeEditDialog" ref="simpleProductNameEdit"/>
  </el-dialog>

  <el-dialog :title="msg('批量查询OK货态')" :visible.sync="queryVisiable" :fullscreen="isMinScreen"  width="450px" @opened="hendleOpenedQuery()">
    <simple-ok-query ref="queryForm" @closeDg="doBatchQuery"/>
  </el-dialog> 


</div>
</template> 

<script>
// @ is an alias to /src
import SimpleProductNameEdit from "./SimpleProductNameEdit.vue";
import SimpleOkQuery from "./SimpleOkQuery.vue";

export default {
  name: 'SimpleProductName',
  components: {
    SimpleProductNameEdit: SimpleProductNameEdit,
    SimpleOkQuery:SimpleOkQuery
  },
  mounted:function(){
    this.getSimpleProductNames();
    var _this = this;
    this.columns = this.$kit.getColumns('exp-spn-filter');
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          name: "",
          simpleName: "",
          short_name: "",
        },
        currentPage: 1,   //分页的当前页
        simpleProductNames: [],   //表格数据列表
        dglogTitle: this.msg('编辑商品名'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        curr:null,

        queryVisiable: false, //查询弹窗
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    //批量查询
    doBatchQuery(data){
      this.queryVisiable = false;
      if(data){
        var _this = this;
        this.query['codes'] = data.codes;
        var param = this.$kit.rebuildQuery(this.query);
        window.location.href= this.$kit.api.exportOk+"?data="+encodeURIComponent(JSON.stringify(param));
        this.query['codes'] = null;
      }else{
      this.query['codes'] = null;
      }
    },
    hendleOpenedQuery(){
      this.$nextTick(() => {
        this.$refs.queryForm.init();
      });
      
    },
    getSimpleProductNames: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
        this.currentPage = 1;
      }

      this.axios.post(this.$kit.api.simpleProductNameList, param).then(function (result) {
        if(result.status){
          if(_this.simpleProductNames.length>0)
            _this.simpleProductNames.splice(0, _this.simpleProductNames.length);
          
          _this.total = result.data.recordsTotal;
          var orderDatas = result.data.data;

          for(var i=0; i<orderDatas.length; i++){
            var item = orderDatas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }

            _this.simpleProductNames.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getSimpleProductNames();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getSimpleProductNames();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getSimpleProductNames();
    },
    add(){  //添加
      this.currId = null;
      this.curr = {
        name: null,
        simpleName: null,
        shortName: null,
      };
      this.dglogTitle = this.msg("添加品名");
      
      this.dialogTableVisible = true;
    },
    edit(){  //修改
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length>1){
        this.$message(this.msg('不能批量编辑'));
        return;
      }
      _this.curr = selectes[0];
      _this.currId = _this.curr.id;
      _this.dglogTitle = this.msg("编辑品名");
      _this.dialogTableVisible = true;

    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getSimpleProductNames();
      }
      this.dialogTableVisible = false;
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map( o => o.id);
      
      var _this = this;

      this.$confirm(this.msg('确定要删除该订单吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.simpleProductNameDelete, ids)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getSimpleProductNames();
          });
        });
    },
  }
}
</script>

<style class="scss" scoped>


</style>
