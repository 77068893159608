<template>
<div>

    <span>类型：</span>
    <el-select v-model="type">
        <el-option :label="msg('寄件编号')" :value="1"></el-option>
    </el-select>
 
    <el-input type="textarea"  :rows="10" :placeholder="msg('please input codes')" 
        v-model="codes" style="margin-top:15px;">
    </el-input>

    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini" @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</div> 
</template>

<script>
export default {
  name: 'ExpQuery',
  data(){
    return{
        type: 1,
        codes: null,
    }
    
  }, 
  mounted: function(){
      
  },
  methods:{
    init(){
        this.codes = null
    },
    onSubmit(){
        if(!this.codes){
            this.$message(this.msg('请输入单号'));
            return;            
        }

        let cs = this.codes.split(/\s+/);
        let css = [];
        for(let i=0; i<cs.length; i++){
            if(cs[i]) css.push(cs[i]);
        }
        
        if(css.length==0){
            this.$message(this.msg('请输入有效数据'));
            return;
        }

        this.$emit("closeDg", {type: this.type, codes:  css.join(",")})

    },

  }
}
</script>

<style class="scss" scoped>


</style>
