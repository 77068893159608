<template>
<el-form ref="form" :model="simpleProductName" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('商品名')">
                <el-input v-model="simpleProductName.name" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('规格名')">
                <el-input v-model="simpleProductName.simpleName" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('短商品名')">
                <el-input v-model="simpleProductName.shortName" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'SimpleProductNameEdit',
  data(){
    return{
       
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      }
  },
  props:{
      id:null,
      simpleProductName:Object,
  },
  mounted: function(){
        var _this = this;
       
  },
  methods:{
    onSubmit(){
        var url = null;
        if(this.id==null){
            url = this.$kit.api.simpleProductNameCreate;
        }else{
            url = this.$kit.api.simpleProductNameUpdate;
        }
        delete this.simpleProductName.creater;
        var _this = this;
        this.$axios.post(url, this.simpleProductName)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
